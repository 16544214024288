<template>
  <GMapMap
    ref="myMapRef"
    :center="mapCenter"
    :zoom="zoom"
    style="width: 100%; height: 100%"
    class="h-full"
    :options="{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      scrollwheel: true,
    }"
    @bounds_changed="handleBoundsChanged"
  >
    <GMapMarker
      v-for="(property, index) in properties"
      :key="index"
      :icon="
        !defaultMarker
          ? {
              url: '/maps-marker.svg',
              size: { width: 100, height: 100 },
              anchor: { x: 50, y: 50 },
            }
          : ''
      "
      :position="{ lat: Number(property.latitude), lng: Number(property.longitude) }"
    />
  </GMapMap>
</template>

<script setup lang="ts">
import { DICTIONARIES, PROPERTIES_SALE_STATUSES } from '~/constants'
import type { LibraryItem, PropertyTypes } from '~/types'

const emits = defineEmits(['filter-status', 'updated:position'])

type Props = {
  properties: Partial<PropertyTypes.Property>[]
  filters?: PropertyTypes.Filters
  zoom?: number
  centerByMarker?: boolean
  showStatuses?: boolean
  defaultMarker?: boolean
  showControls?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  filters: undefined,
  zoom: 12,
  showControls: true,
})

const handleBoundsChanged = (bounds: any) => {
  emits('updated:position', {
    topLeftLat: bounds.getNorthEast().lat(),
    topLeftLng: bounds.getSouthWest().lng(),
    bottomRightLat: bounds.getSouthWest().lat(),
    bottomRightLng: bounds.getNorthEast().lng(),
  })
}

const mapCenter = ref(
  props.centerByMarker
    ? { lat: props.properties[0].latitude, lng: props.properties[0].longitude }
    : { lat: 25.2048, lng: 55.2708 }
)
const myMapRef = ref(null)

onMounted(() => addControls())

const addControls = () => {
  if (!myMapRef.value) return
  myMapRef.value.$mapPromise?.then((map: any) => {
    if (!props.showControls) return
    addControl(map, 'zoomIn', google.maps.ControlPosition.LEFT_TOP)
    addControl(map, 'zoomOut', google.maps.ControlPosition.LEFT_TOP)
    addControl(map, 'center', google.maps.ControlPosition.LEFT_TOP)
    if (props.showStatuses) {
      setTimeout(async () => {
        const statuses = await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_SALE_STATUSES)
        addStatusesButtons(map, statuses)
      }, 200)
    }
  })
}

const createButton = (html: string, classes: string, ariaLabel: string, onClick: () => void, isActive?: boolean) => {
  const button = document.createElement('button')
  button.innerHTML = html
  button.className = classes + (isActive ? 'bg-primary-90 text-white' : 'bg-white text-black-90') // Add 'active' class if isActive is true
  button.setAttribute('aria-label', ariaLabel)
  button.addEventListener('click', onClick)
  return button
}

const statusesButtonsColorsMap = {
  [PROPERTIES_SALE_STATUSES.ANNOUNCEMENT]: 'bg-secondary-120',
  [PROPERTIES_SALE_STATUSES.PRE_SALE]: 'bg-success-100',
  [PROPERTIES_SALE_STATUSES.LAUNCH]: 'bg-alert-100',
  [PROPERTIES_SALE_STATUSES.ON_SALE]: 'bg-primary-100',
}

const addStatusesButtons = (map: any, statuses: LibraryItem[]) => {
  const buttonDiv = document.createElement('div')
  buttonDiv.id = 'status-buttons'
  buttonDiv.classList.add('flex', 'flex-row', 'justify-start', 'rounded-xl', 'm-6', 'gap-2')

  statuses
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((status) =>
      [
        PROPERTIES_SALE_STATUSES.ANNOUNCEMENT,
        PROPERTIES_SALE_STATUSES.PRE_SALE,
        PROPERTIES_SALE_STATUSES.LAUNCH,
        PROPERTIES_SALE_STATUSES.ON_SALE,
      ].includes(status.code)
    )
    .forEach((status) => {
      const button = createButton(
        `<span class="size-3 rounded-full ${statusesButtonsColorsMap[status.code]}"></span>${status.name}`,
        'bg-white p-2 rounded-md flex justify-center shadow-lg text-subhead-4 flex-row gap-2 items-center ',
        status.name,
        () => {
          if (props.filters?.sale_status?.includes(status.id)) {
            button.classList.remove('bg-primary-90')
            button.classList.remove('text-white')

            button.classList.add('bg-white')
            button.classList.add('text-black-90')
          } else {
            button.classList.remove('bg-white')
            button.classList.remove('text-black-90')

            button.classList.add('bg-primary-90')
            button.classList.add('text-white')
          }
          emits('filter-status', status.id)
        },
        props.filters.sale_status?.includes(status.id)
      )
      buttonDiv.appendChild(button)
    })

  map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(buttonDiv)
}

const addControl = (map: any, type: 'center' | 'zoomIn' | 'zoomOut', position: string) => {
  const controlDiv = document.createElement('div')
  controlDiv.classList.add('flex', 'flex-col', 'rounded-xl', 'w-10', 'mx-6')

  let button: HTMLButtonElement
  switch (type) {
    case 'center':
      button = createButton(
        '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1812_17864)"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 7.63636C9.58909 7.63636 7.63636 9.58909 7.63636 12C7.63636 14.4109 9.58909 16.3636 12 16.3636C14.4109 16.3636 16.3636 14.4109 16.3636 12C16.3636 9.58909 14.4109 7.63636 12 7.63636ZM21.7527 10.9091C21.2509 6.36 17.64 2.74909 13.0909 2.24727V1.09091C13.0909 0.490909 12.6 0 12 0C11.4 0 10.9091 0.490909 10.9091 1.09091V2.24727C6.36 2.74909 2.74909 6.36 2.24727 10.9091H1.09091C0.490909 10.9091 0 11.4 0 12C0 12.6 0.490909 13.0909 1.09091 13.0909H2.24727C2.74909 17.64 6.36 21.2509 10.9091 21.7527V22.9091C10.9091 23.5091 11.4 24 12 24C12.6 24 13.0909 23.5091 13.0909 22.9091V21.7527C17.64 21.2509 21.2509 17.64 21.7527 13.0909H22.9091C23.5091 13.0909 24 12.6 24 12C24 11.4 23.5091 10.9091 22.9091 10.9091H21.7527ZM12 19.6364C7.77818 19.6364 4.36364 16.2218 4.36364 12C4.36364 7.77818 7.77818 4.36364 12 4.36364C16.2218 4.36364 19.6364 7.77818 19.6364 12C19.6364 16.2218 16.2218 19.6364 12 19.6364Z" fill="#3D3F44"/></g><defs><clipPath id="clip0_1812_17864"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>',
        'bg-white p-2 rounded-xl !text-2xl h-10 flex justify-center shadow-lg',
        'Center map',
        () => map.setCenter(mapCenter.value)
      )
      break
    case 'zoomIn':
      button = createButton(
        '<svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.75 5C12.75 4.58579 12.4142 4.25 12 4.25C11.5858 4.25 11.25 4.58579 11.25 5V11.25H5C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H11.25V19C11.25 19.4142 11.5858 19.75 12 19.75C12.4142 19.75 12.75 19.4142 12.75 19V12.75H19C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25H12.75V5Z" fill="currentColor"/></svg>',
        'bg-white p-2 rounded-t-md !text-2xl h-10 flex justify-center mt-6 shadow-lg',
        'Zoom in',
        () => map.setZoom(map.getZoom() + 1)
      )
      break
    case 'zoomOut':
      button = createButton(
        '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12Z" fill="currentColor"/></svg>',
        'bg-white p-2 rounded-b-md !text-2xl flex justify-center mb-3 shadow-lg',
        'Zoom out',
        () => map.setZoom(map.getZoom() - 1)
      )
      break
  }

  controlDiv.appendChild(button)
  map.controls[position].push(controlDiv)
}

const resetButtons = () => {
  const buttons = document.querySelectorAll('#status-buttons button')
  buttons.forEach((button) => {
    button.classList.remove('bg-primary-90')
    button.classList.remove('text-white')

    button.classList.add('bg-white')
    button.classList.add('text-black-90')
  })
}

defineExpose({ resetButtons })
</script>

<style scoped></style>
